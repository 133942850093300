<template>
  <div>
    <div class="text-center mt-5" v-if="loadingAction">
      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      <p class="mt-2">{{textLoading}}</p>
    </div>
    <div class="d-flex justify-center align-center full">
      <video id="video" width="720" height="560" autoplay muted></video>
      <canvas id="reflay" class="overlay"></canvas>
    </div>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="2000" class="mb-5">
      <div class="text-center">
        {{ text }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import * as faceapi from 'face-api.js'

export default {
  name: 'Camera',
  data() {
    return {
      video: '',
      stream: null,
      interval: null,
      loading: false,
      textLoading: '',
      snackbar: false,
      snackbarColor: '',
      text: '',
      myTimeOut1: null,
      myTimeOut2: null,
      myTimeOut3: null,
      myTimeOut4: null,
    }
  },
  computed: {
    currentEmployee() {
      return this.$store.getters['manageSalary/getCurrentEmployee']
    },
    loadingAction() {
      return this.loading
    }
  },
  async mounted() {
    this.loading = true;
    this.textLoading = 'Preparing your camera...'
    this.video = document.getElementById('video')
    await Promise.all([
      faceapi.nets.faceRecognitionNet.loadFromUri('/models/'),
      faceapi.nets.faceLandmark68Net.loadFromUri('/models/'),
      faceapi.nets.ssdMobilenetv1.loadFromUri('/models/'),
      faceapi.nets.tinyFaceDetector.loadFromUri('/models/'),
      faceapi.nets.faceExpressionNet.loadFromUri('/models/'),
    ])
    this.faceRecognition()
  },
  destroyed() {
    this.stopVideoCamera(this.stream)
    clearInterval(this.interval)
    clearTimeout(this.myTimeOut1)
    clearTimeout(this.myTimeOut2)
    clearTimeout(this.myTimeOut3)
    clearTimeout(this.myTimeOut4)
  },
  methods: {
    async faceRecognition() {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {},
        })
        const canvas = document.getElementById('reflay')
        const labeledFaceDescriptors = await this.loadLabeledImages()
        const faceMatcher = new faceapi.FaceMatcher(labeledFaceDescriptors, 0.35)
        this.video.srcObject = this.stream
        this.video.addEventListener('play', () => {
          this.loading = false;
          const displaySize = { width: this.video.width, height: this.video.height }
          faceapi.matchDimensions(canvas, displaySize)
          let flagCounterSuccess = 0
          let flagCounterFailed = 0
          this.interval = setInterval(async () => {
            const detections = await faceapi
              .detectAllFaces(this.video, new faceapi.TinyFaceDetectorOptions())
              .withFaceLandmarks()
              .withFaceDescriptors()
            const resizedDetections = faceapi.resizeResults(detections, displaySize)
            canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height)
            const results = resizedDetections.map(d => faceMatcher.findBestMatch(d.descriptor))
            if (results.length) {
              flagCounterFailed++
              if (
                results[0].label === this.currentEmployee.name &&
                results[0].distance <= 0.35 &&
                results[0].distance > 0
              ) {
                if (flagCounterSuccess === 0) {
                  flagCounterSuccess++
                  this.loading = true;
                  this.textLoading = 'Verifying your face...'
                  this.stopVideoCamera(this.stream)
                  this.video.remove()
                  this.updateStatusPayment()
                  clearTimeout(this.myTimeOut3)
                  clearTimeout(this.myTimeOut4)
                  this.myTimeOut1 = setTimeout(() => {
                    this.loading = false
                    this.snackbarColor = 'success'
                    this.text = `Face is match, payment has been completed`
                    this.snackbar = true
                    clearTimeout(this.myTimeOut1)
                  }, 2000)
                  this.myTimeOut2 = setTimeout(() => {
                    this.$router.replace(`/manage-cashier/${this.$route.params.sub_id_disbursement_id}`)
                    clearTimeout(this.myTimeOut2)
                  }, 4000)
                }
              } else {
                if (flagCounterFailed === 1) {
                  this.myTimeOut3 = setTimeout(() => {
                    this.stopVideoCamera(this.stream)
                    this.video.remove()
                    this.snackbarColor = '#E53935'
                    this.text = `Time out, face is not match`
                    this.snackbar = true
                    clearTimeout(this.myTimeOut3)
                  }, 15000)
                  this.myTimeOut4 = setTimeout(() => {
                    this.$router.replace(`/manage-cashier/${this.$route.params.sub_id_disbursement_id}`)
                    clearTimeout(this.myTimeOut4)
                  }, 17000)
                }
              }
            }
            results.forEach((result, i) => {
              const box = resizedDetections[i].detection.box
              const drawBox = new faceapi.draw.DrawBox(box, { label: result.toString() })
              drawBox.draw(canvas)
            })
            faceapi.draw.drawFaceLandmarks(canvas, resizedDetections)
          }, 100)
        })
      } catch (error) {
        console.log(error)
      }

      //     const detections = await faceapi
      //       .detectAllFaces(this.video, new faceapi.TinyFaceDetectorOptions())
      //       .withFaceLandmarks()
      //       .withFaceExpressions()

      //     const resizedDetections = faceapi.resizeResults(detections, displaySize)

      //     canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height)
      //     faceapi.draw.drawDetections(canvas, resizedDetections)
      //     faceapi.draw.drawFaceLandmarks(canvas, resizedDetections)
      //     faceapi.draw.drawFaceExpressions(canvas, resizedDetections)
    },
    // stop video camera
    stopVideoCamera(stream) {
      stream.getTracks().forEach(function (track) {
        if (track.readyState == 'live' && track.kind === 'video') {
          track.stop()
        }
      })
    },
    async loadLabeledImages() {
      await this.$store.dispatch('manageSalary/getEmployeeByID', {
        sub_id: this.$route.params.sub_id_disbursement_id.split('-')[0],
        ID_employee: this.$route.params.idEmployee,
      })
      const label = this.currentEmployee.name
      // const images = ['1.png', '2.png', '3.png'];
      // return Promise.all(
      // labels.map(async label => {
      const descriptions = []
      this.currentEmployee.employeePhotos.forEach(async image => {
        // const img = await faceapi.fetchImage(`/${label}/${image}`)
        try {
          const img = await faceapi.fetchImage(`${image}`)
          const detections = await faceapi.detectSingleFace(img).withFaceLandmarks().withFaceDescriptor()
          if (!detections) throw { message: "Can't detecting face on label photo...." }
          descriptions.push(detections.descriptor)
        } catch (error) {
          console.log(error.message)
        }
      })

      return new faceapi.LabeledFaceDescriptors(label, descriptions)
      // }),
      // )
    },
    async updateStatusPayment() {
      await this.$store.dispatch('manageSalary/updateDisbursmentToPaid', {
        sub_id: this.$route.params.sub_id_disbursement_id.split('-')[0],
        disbursementId: this.$route.params.sub_id_disbursement_id.split('-')[1],
        employeeId: this.$route.params.idEmployee,
        id: 'nothing',
      })
    },
  },
}
</script>
<style>
.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.full {
  width: 100vw;
  height: 100vh;
}
</style>
